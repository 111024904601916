import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FoodLog from './pages/FoodLog';
import Statistics from './pages/Statistics';
import Profile from './pages/Profile';
import NavBar from './components/NavBar';
import { AuthProvider } from './contexts/AuthContext';
import { useAuth } from './hooks/useAuth';

const AppContent = () => {
  const { loginData } = useAuth();

  return (
    <>
      {loginData ? (
        <Router>
          <NavBar />
          <Routes>
            <Route path="/statistics" element={<Statistics />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/" element={<FoodLog />} />
          </Routes>
        </Router>
      ) : (
        <div>
          <p>Please sign in to continue</p>
        </div>
      )}
    </>
  );
};

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
