import React, { useState, useEffect } from 'react';
import { List, ListItemButton, ListItemText, TextField } from '@mui/material';
import FoodItemModal from './FoodItemModal';
import './FoodList.css'; // Import the CSS file
import { useApi } from '../../hooks/useApi';

const FoodList = ({ date, onFoodAdded }) => {
  const [foods, setFoods] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const api = useApi();

  useEffect( () => {
    async function fetchFoods() {
      try {
        const response = await api.get('/food');
        setFoods(response.data);
      } catch (error) {
        console.error('Error fetching the food list:', error);
      }
    }
  
    fetchFoods();
  }, []);

  const handleFoodClick = (food) => {
    setSelectedFood(food);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFoods = foods.filter(food => 
    food.name.toLowerCase().includes(searchTerm.toLowerCase())
  );


  return (
    <div className="food-list-container">
      <h2>Food List</h2>
      <TextField
        label="Search Foods"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
     <List className="scrollable-list">
        {filteredFoods.map(food => (
          <ListItemButton 
            key={food.id} 
            onClick={() => handleFoodClick(food)}
          >
            <ListItemText primary={`${food.name} - ${food.kcal} kcal/100g`} />
          </ListItemButton>
        ))}
      </List>
      {selectedFood && (
         <FoodItemModal
         food={selectedFood}
         date={date}
         amount=""
         onClose={() => setSelectedFood(null)}
         onFoodAdded={onFoodAdded}
         onFoodDeleted={() => {}}
         isEditing={false} // Indicate that this is for adding a new entry
       />
      )}
    </div>
  );
};

export default FoodList;