import React from 'react';
import { useAuth } from '../hooks/useAuth';

const Profile = () => {
const { logout } = useAuth();
const handleLogout = () => {
  console.log("Handlelogout");
  logout();
};

  return (
    <div>
      <h1>Profile</h1>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Profile;