import React, { useState, useEffect } from 'react';
import { List, ListItemButton, ListItemText, Box, Typography } from '@mui/material';
import axios from 'axios';
import Summary from './Summary';
import LoadingOverlay from '../LoadingOverlay'; 
import FoodItemModal from './FoodItemModal';
import { format } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';

const DailyLog = ({ date }) => {
  const [dailyFoods, setDailyFoods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFood, setSelectedFood] = useState(null);
  const [summary, setSummary] = useState(null);
  const api = useApi();


  const fetchDailyFoods = async (formattedDate) => {
    setLoading(true);
    try {
      const response = await api.get(`/foodentry/${formattedDate}`);
      setDailyFoods(response.data);
      fetchSummary(formattedDate);
    } catch (error) {
      console.error('Error fetching daily foods:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSummary = async (formattedDate) => {
    try {
      const response = await api.get(`/foodentry/totals/${formattedDate}`);
      setSummary(response.data);
    } catch (error) {
      console.error('Error fetching summary:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    fetchDailyFoods(formattedDate);
  }, [date]);

  const handleFoodClick = (food) => {
    setSelectedFood(food);
  };

  const handleFoodUpdated = () => {
    fetchDailyFoods(date);
    setSelectedFood(null);
  };

  const handleFoodDeleted = async (id) => {
    try {
      await api.delete(`/foodEntry/${id}`);
      handleFoodUpdated(); // Refresh the daily foods
    } catch (error) {
      console.error('Error deleting food entry:', error);
    }
  };



  return (
    <Box position="relative">
      {loading && <LoadingOverlay message="Loading daily foods..." />}
      {dailyFoods.length === 0 ? (
        <Typography variant="h6" align="center">No foods added for this day.</Typography>
      ) : (
        <List>
          {dailyFoods.map(food => (
            <ListItemButton key={food.id} onClick={() => handleFoodClick(food)}>
              <ListItemText primary={`${food.name} - ${food.amount}g`} />
            </ListItemButton>
          ))}
        </List>
      )}
      {summary && <Summary data={summary} />}
      {selectedFood && (
        <FoodItemModal
          food={selectedFood}
          date={date}
          amount={selectedFood.amount}
          onClose={() => setSelectedFood(null)}
          onFoodAdded={handleFoodUpdated}
          onFoodDeleted={handleFoodDeleted}
          isEditing={true}
        />
      )}
    </Box>
  );
};

export default DailyLog;