import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';
import './LoadingOverlay.css';

const LoadingOverlay = ({ message }) => {
  return (
    <div className="loading-overlay">
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: '10px' }}>{message}</Typography>
      </Box>
    </div>
  );
};

export default LoadingOverlay;