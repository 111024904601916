import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import googleOneTap from 'google-one-tap';

export const AuthContext = createContext();

const options = {
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  auto_select: false,
  cancel_on_tap_outside: false,
  context: 'signin'
};

export const AuthProvider = ({ children }) => {
  const [loginData, setLoginData] = useState(() => {
    const storedAuth = localStorage.getItem('loginData');
    return storedAuth ? JSON.parse(storedAuth) : null;
  });

  useEffect(() => {
    if (!loginData) {
      googleOneTap(options, async (response) => {
        try {
          const result = await axios.post(process.env.REACT_APP_SERVER_URL + '/auth/google', {
            token: response.credential
          });
          setLoginData(result.data);
          localStorage.setItem('loginData', JSON.stringify(result.data));
          axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;
        } catch (error) {
          if (error.response && error.response.status === 401) {
            console.error('User not found');
            alert('User not found');
          } else {
            console.error('An error occurred:', error);
            alert('An error occurred during authentication');
          }
        }
      });
    }
  }, [loginData]);

  useEffect(() => {
    if (loginData) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${loginData.token}`;
      localStorage.setItem('loginData', JSON.stringify(loginData));
    } else {
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('loginData');
    }
  }, [loginData]);

  const login = (userData) => {
    setLoginData(userData);
  };

  const logout = () => {
    setLoginData(null);
  };

  return (
    <AuthContext.Provider value={{ loginData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
