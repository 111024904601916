import React from 'react';
import { Box, Typography } from '@mui/material';

const Summary = ({ data }) => {
  return (
    <Box marginTop={2}>
      <Typography variant="h6">Daily Summary</Typography>
      <Typography>Kcal: {data.kcal}</Typography>
      <Typography>Protein: {data.protein}g</Typography>
      <Typography>Carbs: {data.carbs}g</Typography>
      <Typography>Sugar: {data.sugar}g</Typography>
      <Typography>Fiber: {data.fiber}g</Typography>
      <Typography>Fats: {data.fats}g</Typography>
      <Typography>Sat Fat: {data.sat_fat}g</Typography>
      <Typography>Poly Fat: {data.poly_fat}g</Typography>
      <Typography>Mono Fat: {data.mono_fat}g</Typography>
      <Typography>Carb Kcal: {data.carb_kcal}</Typography>
      <Typography>Protein Kcal: {data.protein_kcal}</Typography>
      <Typography>Fat Kcal: {data.fat_kcal}</Typography>
      <Typography>Carb %: {data.carb_prec}%</Typography>
      <Typography>Protein %: {data.protein_prec}%</Typography>
      <Typography>Fat %: {data.fat_prec}%</Typography>
    </Box>
  );
};

export default Summary;