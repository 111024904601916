import React from 'react';
import { TextField } from '@mui/material';
import { format, parseISO } from 'date-fns';

const DateSelector = ({ date, onDateChange }) => {
  return (
    <TextField
      type="date"
      value={format(date, 'yyyy-MM-dd')}
      onChange={(e) => onDateChange(parseISO(e.target.value))}
      fullWidth
    />
  );
};

export default DateSelector;