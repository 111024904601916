import React from 'react';

const Statistics = () => {
  return (
    <div>
      <h1>Statistics</h1>
    </div>
  );
};

export default Statistics;