// src/hooks/useApi.js
import { useAuth } from './useAuth';
import axios from 'axios';

export const useApi = () => {
  const { auth, logout } = useAuth();

  const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
  });

  api.interceptors.request.use(
    (config) => {
      if (auth && auth.token) {
        config.headers['Authorization'] = `Bearer ${auth.token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return api;
};