import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

import LoadingOverlay from '../LoadingOverlay';
import { format } from 'date-fns';
import { useAuth } from '../../hooks/useAuth';
import { useApi } from '../../hooks/useApi';


const FoodItemModal = ({ food, date, amount: initialAmount, onClose, onFoodAdded, onFoodDeleted, isEditing}) => {
  const [amount, setAmount] = useState(initialAmount || '');
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const api = useApi();


  useEffect(() => {
    setAmount(initialAmount || '');
  }, [initialAmount]);
  
  const handleAddFood = async () => {
    setLoading(true);
    date = format(date, 'yyyy-MM-dd');
    try {
      if (isEditing && initialAmount) {
        // Delete the old record
        await api.delete(`/foodentry/${food.id}`);
      }
      // Add the new record
      await api.post('/foodentry', {
        foodId: food.id,
        date: date,
        amount,
      });
      onFoodAdded(); // Call the callback to reload the daily foods
    } catch (error) {
      setLoading(false);
      console.error('Error adding food entry:', error);
    }
    setLoading(false);
    onClose();
  };

  const handleDeleteFood = async () => {
    setLoading(true);
    try {
      await api.delete(`/foodentry/${food.id}`);
      onFoodDeleted(food.id); // Call the callback to delete the food entry
    } catch (error) {
      console.error('Error deleting food entry:', error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{food.name}</DialogTitle>
      <DialogContent>
        <p>Fat: {food.fats}g</p>
        <p>Protein: {food.protein}g</p>
        <p>Carbs: {food.carbs}g</p>
        <p>Kcal: {food.kcal} kcal/100g</p>
        <TextField
          label="Amount (g)"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddFood}>Save</Button>
        {isEditing && <Button onClick={handleDeleteFood} color="secondary">Delete</Button>}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FoodItemModal;