import React, { useState } from 'react';
import FoodList from '../components/FoodLog/FoodList';
import DailyLog from '../components/FoodLog/DailyLog';
import DateSelector from '../components/FoodLog/DateSelector';
import './FoodLog.css'; 

const FoodLog = () => {
  const [date, setDate] = useState(new Date());
  
  const handleDateChange = (selectedDate) => {
    console.log(selectedDate);
    setDate(selectedDate);
  };

  const refreshDailyLog = () => {
    // This will force DailyLog to re-render and fetch the updated data
    setDate(new Date(date));
  
  };



    return (
    <div className="food-log-page">
         
        <div className="food-list-section">
            <FoodList date={date} onFoodAdded={refreshDailyLog} />
      </div>
        <div className="daily-log-section">
        <DateSelector date={date} onDateChange={handleDateChange} />
          <DailyLog date={date} />
        </div>
    </div>
      );
    }

export default FoodLog;